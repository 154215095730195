<template>
  <div class="bussiness">
    <div class="public_title">工商信息</div>

    <div class="flex row">
      <div class="desc_item">
        <div class="label">企业法人</div>
        <div class="value">辛杰</div>
      </div>

      <div class="desc_item">
        <div class="label">经营状态</div>
        <div class="value">存续</div>
      </div>
    </div>

    <div class="flex row">
      <div class="desc_item">
        <div class="label">成立时间</div>
        <div class="value">1998-07-31</div>
      </div>

      <div class="desc_item">
        <div class="label">注册资本金</div>
        <div class="value">46681221万人民币</div>
      </div>
    </div>

    <div class="flex row">
      <div class="desc_item">
        <div class="label">经营期限</div>
        <div class="value">1998-07-31 至 2067-07</div>
      </div>

      <div class="desc_item">
        <div class="label">统一信用代码</div>
        <div class="value">912392485784329H</div>
      </div>
    </div>

    <div class="flex row">
      <div class="desc_item">
        <div class="label">企业官网</div>
        <div class="value">https：www….</div>
      </div>

      <div class="desc_item">
        <div class="label">联系方式</div>
        <div class="value">0755-889690888</div>
      </div>
    </div>

    <div class="flex row">
      <div class="desc_item">
        <div class="label">邮箱</div>
        <div class="value">8073428943@qq.com</div>
      </div>

      <div class="desc_item">
        <div class="label">企业曾用名</div>
        <div class="value">深圳市地铁有限公司</div>
      </div>
    </div>

    <div class="desc_item row">
      <div class="label">详细地址</div>
      <div class="value">深圳市福田区莲花街道福中一路1016号地</div>
    </div>

    <div class="desc_item row">
      <div class="label">经营范围</div>
      <div class="value">
        地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合地铁、轻轨交通项目的建设经营、开发和综合
      </div>
    </div>
  </div>
</template>

<script>
/* 企业详情-工商信息 */

export default {
  props: ["details"],
};
</script>

<style lang="less" scoped>
.desc_item {
  flex: 1;
  display: flex;
  align-items: stretch;
  font-size: 14px;
  .label {
    padding: 15px;
    min-width: 200px;
    text-align: center;
    color: #666;
    display: flex;
    align-items: center;
    background-color: #f5faff;
  }

  .value {
    display: flex;
    align-items: center;
    padding: 15px;
    flex: 1;
    color: #666;
    border-right: 1px solid #ecf1ff;
    border-left: 1px solid #ecf1ff;
    line-height: 1.5;
  }
}
.row {
  border: 1px solid #ecf1ff;
  border-bottom: none;
  border-right: none;
  &:last-child {
    border-bottom: 1px solid #ecf1ff;
  }
}
</style>
