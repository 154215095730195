<template>
  <div class="basic_info">
    <companyInfo :details="details" />

    <div class="statistics">
      <div class="statistics_item">
        <div class="title">发包总额</div>
        <div class="content">3878.54 亿</div>
      </div>
      <div class="statistics_item">
        <div class="title">最大发包额</div>
        <div class="content">3878.54 亿</div>
      </div>
      <div class="statistics_item">
        <div class="title">常发包地区</div>
        <div class="content">广东省-深州市</div>
      </div>
      <div class="statistics_item">
        <div class="title">常发包范围</div>
        <div class="content">政府投资</div>
      </div>
    </div>

    <div class="echarts" id="echarts"></div>
  </div>
</template>

<script>
/* 查业主详情-基本信息 */
import companyInfo from "@/views/companyDetails/components/companyInfo.vue";

export default {
  components: { companyInfo },

  data() {
    return {
      details: {
        id: 17959,
        name: "远海建工（集团）有限公司",
        nameUsed:
          "重庆市万州实业有限公司\t;\t重庆远海建工（集团）有限公司\t;\t重庆市万州实业（集团）有限公司\t;\t",
        recentResultTime: "2024-09-29",
        type: "民企",
        joinIn: "是",
        regAddress: "重庆市两江新区龙兴镇腾云路8号5幢",
        sell: "否",
        regCost: "100000万人民币",
        remark:
          "同资质的公司全国就两家，公司配合度高，2个亿的水利项目已完工等验收",
        companyId: null,
        bidNum: null,
        sort: null,
        tag: null,
        document: "[]",
        rmd: "是",
        personnelNum: 927,
        personnelProList: null,
        mgrNum: null,
        certNum: 18,
        honorNum: 149,
        icon: '[{"name":"5cb4b4bc-48bd-4df0-9c94-937af0bdf50c.jpg","url":"https://www.jzzz888.com:443/upload/5cb4b4bc-48bd-4df0-9c94-937af0bdf50c.jpg","uid":1715306446255,"status":"success"}]',
        companyTag: "房建特级,市政一级,水利一级,公路一级",
        joinBtnShow: "是",
        cooperativePolicy:
          "<p>可以单项，具体看项目定价，承包只能省级110万年费，10万押金，客户必须有像样的办公场地，2个亿以内不收管理费，超过0.5</p>",
        welfarePolicy: "<p>阿尔法上午</p>",
        rebateModel: "<p>热风热伤风</p>",
        legalPerson: "李远海",
        establishmentTime: "1998-03-02",
        state: "存续",
        taxpayerId: "9150000071160023XL",
        phone: "-",
        otherPhone:
          "023-61805555;023-61805876;023-61805833;010-61805833;02361805555",
        insured: "188",
        companyUrl:
          "https://www.cninct.com/#/companyinfo/managementinfo/personInformation?companyId=17959&name=远海建工（集团）有限公司&company_type=施工单位",
        businessScope:
          "许可项目：餐饮服务；酒类经营；住宿服务。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准） 一般项目：酒店管理；业务培训（不含教育培训、职业技能培训等需取得许可的培训）；人力资源服务（不含职业中介活动、劳务派遣服务）；建筑工程施工总承包特级；建筑行业（建筑工程、人防工程）设计甲级；市政公用工程施工总承包壹级；地质灾害治理工程施工甲级；公路工程施工总承包壹级；水利水电工程施工总承包壹级；地基与基础工程专业承包贰级；土石方工程专业承包壹级；公路路面工程专业承包贰级；公路路基工程专业承包贰级；爆破与拆除工程专业承包贰级；钢结构工程专业承包壹级；河湖整治工程专业承包叁级；建筑机电安装工程专业承包叁级；建筑装修装饰工程专业承包壹级（凭资质证书执业）;城市园林绿化施工；销售建筑材料（不含危险化学品）、五金、交电、木、竹材及制品，普通仓储服务（国家有专项规定的除外）；工程技术咨询服务。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）",
        businessTerm: "1998-03-02 至 无固定期限",
        regCapital: null,
        province: "重庆市",
        city: "-",
        district: "万州区",
        showIndex: 1,
        introduction: "水利3A，安标一级，实体公司，",
        paidupCapital: "100000万人民币",
        managerNum: "13",
        managerList: null,
        technicianNum: "0",
        technicianList: null,
        integrityNum: "0",
        evaluateNum: "60",
        resultNum: "87",
        resultList: null,
        industrialInfo: null,
      },
    };
  },

  methods: {
    /* 绘制echart */
    drawEchart() {
      let chartDom = document.getElementById("echarts");
      let myChart = this.$echarts.init(chartDom);
      let option;

      option = {
        title: {
          text: "近五年经营变化",
          top: 0,
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },

        legend: {
          data: ["发包数量", "发包金额（累积）"],
          bottom: 0,
          left: "center",
        },
        xAxis: [
          {
            type: "category",
            data: ["2020", "2021", "2022", "2023", "2025", "2025"],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "发包数量",
            min: 0,
            max: 250,
            interval: 50,
            axisLabel: {
              formatter: "{value}个",
            },
          },
          {
            type: "value",
            name: "发包金额（累积）",
            min: 0,
            max: 25,
            interval: 5,
            axisLabel: {
              formatter: "{value}万元",
            },
          },
        ],
        series: [
          {
            name: "发包数量",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " 个";
              },
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0,
              2.3,
            ],
          },
          {
            name: "发包金额（累积）",
            type: "line",
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + " 万元";
              },
            },
            data: [
              2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2,
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },

  mounted() {
    this.drawEchart();
  },
};
</script>

<style lang="less" scoped>
.basic_info {
  /deep/.company_info {
    padding: 0px !important;
  }

  .statistics {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .statistics_item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 40px;
      padding-top: 40px;
      border-radius: 8px;
      box-shadow: 0px 0px 6px 0px rgba(157, 195, 221, 0.5);
      margin-right: 15px;

      .title {
        font-size: 16px;
        color: #333;
        line-height: 22px;
        margin-bottom: 27px;
      }

      .content {
        color: #1d89e3;
        font-size: 22px;
        font-weight: bold;
        line-height: 30px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .echarts {
    height: 500px;
  }
}
</style>
