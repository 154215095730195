<template>
  <div class="supplier">
    <div class="public_title">供应商</div>

    <el-form ref="form" :model="form" label-width="84px" size="small">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="供应商名称">
            <el-input
              v-model.trim="form.name"
              placeholder="请输入供应商名称"
              clearable
              @keyup.enter.native="query"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="累积金额" label-width="70px">
            <div class="flex alc">
              <el-input
                v-model.number.trim="form.amountMin"
                placeholder="请输入最小值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>

              <span class="ml10 mr10">至</span>

              <el-input
                v-model.number.trim="form.amountMax"
                placeholder="请输入最大值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="合作次数">
            <el-input
              v-model.trim="form.times"
              placeholder=">="
              clearable
              @keyup.enter.native="query"
            >
              <template slot="append">次</template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-button size="small" @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button
            size="small"
            type="primary"
            @click="query"
            icon="el-icon-search"
          >
            查询
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-table
      v-loading="loading"
      :data="list"
      border
      style="width: 100%"
      ref="personnel"
      height="500"
    >
      <el-table-column label="序号" type="index" width="50"> </el-table-column>

      <el-table-column
        sortable
        prop="name"
        min-width="100"
        :show-overflow-tooltip="true"
        label="供应商名称"
      >
        <template slot-scope="scope">
          <div class="link" @click="toDetail(scope.row.id)">
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        sortable
        prop="times"
        min-width="100"
        :show-overflow-tooltip="true"
        label="合作次数"
      ></el-table-column>

      <el-table-column
        sortable
        prop="totalAmount"
        min-width="100"
        :show-overflow-tooltip="true"
        label="累积总金额"
      ></el-table-column>

      <el-table-column
        sortable
        prop="latestTime"
        min-width="100"
        :show-overflow-tooltip="true"
        label="最新合作时间"
      ></el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page="form.pageNum"
      :page-sizes="[20, 50, 100, 150, 200]"
      :page-size="form.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
/* 查业主详情-供应商 */
// 引入ajax函数
import { searchResult } from "@/api/itemizedQuery";
import { getDict } from "@/api/common";
// 引入区域json
import area from "@/utils/area";

export default {
  props: ["companyId"],

  // 数据
  data() {
    return {
      form: {
        name: "",
        amountMin: "",
        amountMax: "",
        times: "",
        pageNum: 1,
        pageSize: 20,
      },
      list: [
        {
          name: "中铁*******公司",
          times: 4,
          totalAmount: "6188524189.39485",
          latestTime: "2023-12-11",
        },
        {
          name: "中铁*******公司",
          times: 4,
          totalAmount: "6188524189.39485",
          latestTime: "2023-12-11",
        },
        {
          name: "中铁*******公司",
          times: 4,
          totalAmount: "6188524189.39485",
          latestTime: "2023-12-11",
        },
        {
          name: "中铁*******公司",
          times: 4,
          totalAmount: "6188524189.39485",
          latestTime: "2023-12-11",
        },
        {
          name: "中铁*******公司",
          times: 4,
          totalAmount: "6188524189.39485",
          latestTime: "2023-12-11",
        },
        {
          name: "中铁*******公司",
          times: 4,
          totalAmount: "6188524189.39485",
          latestTime: "2023-12-11",
        },
      ], // 列表
      total: 0, // 总数
      loading: false, // 加载状态
    };
  },

  watch: {
    companyId: {
      handler(newVal) {
        if (newVal) {
          this.__init__();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;
      const params = {
        ...this.form,
      };

      const { code, data, msg } = await searchResult(params);
      if (code !== 0) return this.$message.error(msg);
      this.list = data.records;
      this.total = data.total;
      this.loading = false;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id) {
      console.log("toDetail");
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        name: "",
        amountMin: "",
        amountMax: "",
        times: "",
        pageNum: 1,
        pageSize: 20,
      };
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.areaOptions = area;
      // this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.link {
  overflow: hidden; // 溢出隐藏
  text-overflow: ellipsis; // 溢出用省略号显示
  display: -webkit-box; // 作为弹性伸缩盒子模型显示。
  -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
  -webkit-line-clamp: 1; // 显示的行数
}
</style>
