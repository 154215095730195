<template>
  <div class="supplier">
    <div class="public_title">建企舆情</div>

    <el-form ref="form" :model="form" label-width="70px" size="small">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="时间">
            <el-date-picker
              style="width: 100%"
              v-model="time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="情感分类">
            <el-select
              style="width: 100%"
              v-model="form.emotionType"
              placeholder="请选择情感分类"
              clearable
            >
              <el-option
                v-for="item in emotionTypeOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="舆情标签">
            <el-select
              style="width: 100%"
              v-model="form.opinionType"
              placeholder="请选择舆情标签"
              clearable
            >
              <el-option
                v-for="item in opinionTypeOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="标题">
            <el-input
              v-model.trim="form.title"
              placeholder="请输入标题关键词"
              clearable
              @keyup.enter.native="query"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24" class="tac mb20">
          <el-button size="small" @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button
            size="small"
            type="primary"
            @click="query"
            icon="el-icon-search"
          >
            查询
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-table
      v-loading="loading"
      :data="list"
      border
      style="width: 100%"
      ref="personnel"
      height="500"
    >
      <el-table-column label="序号" type="index" width="50"> </el-table-column>

      <el-table-column
        sortable
        prop="name"
        min-width="200"
        :show-overflow-tooltip="true"
        label="标题"
      >
        <template slot-scope="scope">
          <div>
            <div class="link inline" @click="toDetail(scope.row.id)">
              {{ scope.row.name }}
            </div>

            <div class="tag emotion_tag inline">
              {{ scope.row.emotionType }}
            </div>
            <div class="tag opinion_tag inline">
              {{ scope.row.opinionType }}
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        sortable
        prop="time"
        min-width="60"
        :show-overflow-tooltip="true"
        label="时间"
      ></el-table-column>

      <el-table-column
        sortable
        prop="source"
        min-width="80"
        :show-overflow-tooltip="true"
        label="来源"
      >
        <template slot-scope="scope">
          <div class="vip">
            {{ scope.row.source }}
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page="form.pageNum"
      :page-sizes="[20, 50, 100, 150, 200]"
      :page-size="form.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
/* 查业主详情-建企舆情 */
// 引入ajax函数
import { searchResult } from "@/api/itemizedQuery";
// 引入区域json
import area from "@/utils/area";

export default {
  props: ["companyId"],

  // 数据
  data() {
    return {
      form: {
        emotionType: "",
        opinionType: "",
        title: "",
        pageNum: 1,
        pageSize: 20,
      },
      time: [], // 时间
      emotionTypeOptions: [],
      opinionTypeOptions: [],

      // 时间选择器快捷选项
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      list: [
        {
          name: "60亿市域列车大单中标结果正式公布",
          emotionType: "中立",
          opinionType: "招投标",
          time: "2024-10-23",
          source: "今日头条",
        },
        {
          name: "60亿市域列车大单中标结果正式公布",
          emotionType: "中立",
          opinionType: "招投标",
          time: "2024-10-23",
          source: "今日头条",
        },
        {
          name: "60亿市域列车大单中标结果正式公布",
          emotionType: "中立",
          opinionType: "招投标",
          time: "2024-10-23",
          source: "今日头条",
        },
        {
          name: "60亿市域列车大单中标结果正式公布",
          emotionType: "中立",
          opinionType: "招投标",
          time: "2024-10-23",
          source: "今日头条",
        },
        {
          name: "60亿市域列车大单中标结果正式公布",
          emotionType: "中立",
          opinionType: "招投标",
          time: "2024-10-23",
          source: "今日头条",
        },
        {
          name: "60亿市域列车大单中标结果正式公布",
          emotionType: "中立",
          opinionType: "招投标",
          time: "2024-10-23",
          source: "今日头条",
        },
      ], // 列表
      total: 0, // 总数
      loading: false, // 加载状态
    };
  },

  watch: {
    companyId: {
      handler(newVal) {
        if (newVal) {
          this.__init__();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;
      const params = {
        ...this.form,
      };

      const { code, data, msg } = await searchResult(params);
      if (code !== 0) return this.$message.error(msg);
      this.list = data.records;
      this.total = data.total;
      this.loading = false;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id) {
      console.log("toDetail");
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        name: "",
        amountMin: "",
        amountMax: "",
        times: "",
        pageNum: 1,
        pageSize: 20,
      };
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.areaOptions = area;
      // this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.tag {
  color: #333333;
  font-size: 12px;
  border: 1px solid #5fe2f1;
  padding: 4px;
  line-height: 1;
  border-radius: 2px;
  margin-left: 5px;
}

.opinion_tag {
  border: 1px solid #1d89e3;
}
</style>
