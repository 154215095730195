<template>
  <div class="details container">
    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane label="基本信息" name="基本信息" lazy>
        <BasicInfo />
      </el-tab-pane>

      <el-tab-pane label="供应商" name="供应商" lazy>
        <Supplier />
      </el-tab-pane>

      <el-tab-pane label="历史发包" name="历史发包" lazy>
        <History />
      </el-tab-pane>

      <el-tab-pane label="最新招标" name="最新招标" lazy>
        <Tender />
      </el-tab-pane>

      <el-tab-pane label="工商信息" name="工商信息" lazy>
        <Business />
      </el-tab-pane>

      <el-tab-pane label="建企舆情" name="建企舆情" lazy>
        <Opinion />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/* 查业主-详情页 */
import BasicInfo from "./components/owner/basicInfo.vue";
import Supplier from "./components/owner/supplier.vue";
import History from "./components/owner/history.vue";
import Tender from "./components/owner/latestTender.vue";
import Business from "./components/owner/business.vue";
import Opinion from "./components/owner/opinion.vue";

export default {
  components: {
    BasicInfo,
    Supplier,
    History,
    Tender,
    Business,
    Opinion,
  },

  data() {
    return {
      activeName: "基本信息",
    };
  },

  methods: {
    /* 操作 */
    // tab的点击事件
    handleTabClick(tab) {
      this.activeName = tab.name;
    },
  },
};
</script>

<style lang="less" scoped>
.details {
  /deep/.el-tabs__header,
  /deep/.el-tabs__content {
    background-color: #fff;
    padding: 20px;
  }

  /deep/.el-tabs__header {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: sticky;
    z-index: 2000;
    top: 70px;
  }

  /deep/.el-collapse-item__content {
    padding-bottom: 0px;
  }
  /deep/.el-descriptions__body {
    padding: 20px;
    background-color: #f5faff;
  }
}
</style>
