import { render, staticRenderFns } from "./ownerDetails.vue?vue&type=template&id=50cd1fc0&scoped=true&"
import script from "./ownerDetails.vue?vue&type=script&lang=js&"
export * from "./ownerDetails.vue?vue&type=script&lang=js&"
import style0 from "./ownerDetails.vue?vue&type=style&index=0&id=50cd1fc0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50cd1fc0",
  null
  
)

export default component.exports